import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import fallback from '../assets/logo.png';
import { mergeClassNames } from '../utils';
import styles from './SkeletonImage.module.css';

export default function SkeletonImage({ skeletonClassName, forceLoad, ...props }) {
  const { ref, isVisible } = useVisibilty();

  const { src, status } = useFallbackLoading(props.src, forceLoad || isVisible);

  return (
    <span ref={ref}>
      {
        status === 'pending' || !src ?
          <Skeleton className={mergeClassNames(props.className, skeletonClassName)} /> :
          <img {...props} src={src} className={mergeClassNames(props.className, styles.image, { [styles.fallback]: status === 'fallback' })} />
      }
    </span>
  )
}

function useFallbackLoading(src, isVisible) {
  const [state, setState] = useState({ status: 'pending', src: undefined });

  useEffect(() => {
    if (!src) {
      setState({ status: 'fallback', src: fallback });
    } else if (isVisible) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setState({ status: 'loaded', src: src });
      };
      img.onerror = () => {
        setState({ status: 'fallback', src: fallback });
      }
    }
  }, [src, isVisible]);

  return state;
}

function useVisibilty() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin: '0px 0px 100px 0px',
      }
    );

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, [ref]);

  return { ref, isVisible };
}