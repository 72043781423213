import React, { useState, Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { mergeClassNames } from '../utils';
import styles from './Modal.module.css';

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible(!isVisible);
  return [isVisible, toggle];
};

const Modal = ({ isVisible, hide, children, wrapperClassName, overlayClassName, modalClassName }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleWrapperClick = (e) => {
    // if (e.target === e.currentTarget) {
      hide();
    // }
  };

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      // Small delay to ensure DOM is ready for animation
      requestAnimationFrame(() => {
        setIsAnimating(true);
      });
    } else {
      setIsAnimating(false);
      // Wait for animation to complete before unmounting
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 200); // Match transition duration
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <Fragment>
      <div 
        className={mergeClassNames(
          styles.overlay, 
          { [styles.overlayVisible]: isAnimating },
          overlayClassName
        )} 
        onClick={handleWrapperClick}
      />
      <div
        className={mergeClassNames(
          styles.wrapper,
          { [styles.wrapperVisible]: isAnimating },
          wrapperClassName
        )}
        onClick={handleWrapperClick}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className={mergeClassNames(styles.modal, modalClassName)}>
          <button
            type="button"
            className={styles.closeButton}
            data-dismiss="modal"
            aria-label="Close"
            onClick={hide}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div>{children}</div>
        </div>
      </div>
    </Fragment>,
    document.body
  );
};

export default Modal;
