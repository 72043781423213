import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { isPhone, mergeClassNames, getNetlifyImageUrl, isWindowSafe } from '../utils';
import Modal from './Modal';

import styles from './CustomSlider.module.css';
import SkeletonImage from './SkeletonImage';

const mainProps = {
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: false,
  arrows: false,
  dots: isPhone(),
  autoplay: false,
};


const previewProps = {
  ...mainProps,
  fade: false,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: false,
  arrows: true,
  variableWidth: true,
};

const singleImage = {
  infinite: false,
  autoplay: false,
};

const modalProps = {
  infinite: true,
  speed: 500,
  fade: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
};

const CustomSlider = ({ attachments, className }) => {
  const main = useRef(null);
  const preview = useRef(null);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  if (!attachments.length) return null;

  const mapItems = (attachments, isMain) => {
    return attachments.map((attachment, i) => {
      const {
        fileName,
        headline: title,
        uri,
      } = attachment;

      const key = `${uri}_${isMain ? 'main' : 'preview'}_${i}`;

      const onClick = () => {
        if (isMain) {
          setFullscreenImage({ uri, title, index: i });
        } else {
          preview?.current && preview.current.slickGoTo(i);
          main?.current && main.current.slickGoTo(i);
        }
      };
      if (fileName?.toLowerCase().includes('.tif')) return null;
      if (fileName?.toLowerCase().includes('.pdf')) {
        return (
          <a
            key={key}
            target="_blank"
            rel="noopener noreferrer"
            href={uri}
            onClick={onClick}
          >
            <div className={styles.slide}>
              <object data={uri} type="application/pdf">
                <embed src={uri} type="application/pdf"></embed>
              </object>
              <p>{fileName}</p>
            </div>
          </a>
        );
      }
      return (
        <div
          key={key}
          className={styles.slide}
          onClick={onClick}
        >
          {isMain && (
            <SkeletonImage
              className={mergeClassNames(styles.image, styles.backgroundFill)}
              src={getNetlifyImageUrl(uri, { w: 100, q: 20 })}
              alt={title}
            />
          )}
          <SkeletonImage
            className={styles.image}
            skeletonClassName={isMain ? styles.skeleton : undefined}
            src={getNetlifyImageUrl(uri, isMain ? { h: 500 } : { w: 300 })}
            alt={title}
          />
          <p className={styles.imageTitle}>{title}</p>
        </div>
      );
    });
  };

  const hasOneImage = attachments.length <= 1;
  const dynamicProps = hasOneImage ? singleImage : {};

  return (
    <>
      <div
        className={mergeClassNames(className, styles.root, {
          [styles.oneImage]: hasOneImage,
        })}
      >
        <Slider
          {...mainProps}
          {...dynamicProps}
          className={styles.main}
          asNavFor={preview?.current}
          ref={main}
        >
          {mapItems(attachments, true)}
        </Slider>
        {!hasOneImage && !isPhone() && (
          <Slider
            {...previewProps}
            {...dynamicProps}
            className={styles.preview}
            asNavFor={main?.current}
            ref={preview}
          >
            {mapItems(attachments)}
          </Slider>
        )}
      </div>

      <Modal
        isVisible={!!fullscreenImage}
        hide={() => setFullscreenImage(null)}
        wrapperClassName={styles.modalWrapper}
        modalClassName={styles.modal}
      >
        {fullscreenImage && (
          <div onClick={e => e.stopPropagation()}>
            <Slider
              {...modalProps}
              initialSlide={fullscreenImage.index}
              className={styles.modalSlider}
            >
              {attachments.map((attachment, i) => (
                <div key={`modal_${attachment.uri}_${i}`}>
                  <img
                    src={getNetlifyImageUrl(attachment.uri, {
                      w: isWindowSafe() ? Math.round(window.innerWidth * 0.95) : 1000
                    })}
                    alt={attachment.headline}
                    onLoad={(evt) => {
                      evt.target.style.opacity = 1;
                    }}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '90vh',
                      objectFit: 'contain'
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </Modal>
    </>
  );
};

export default CustomSlider;
