import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import classnames from 'classnames';
import { Link, navigate } from 'gatsby';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import slug from 'slug';
import estatetypes from './components/estatetypes';

export const ALL = 'all';

export const useResizeListener = (callback) => {
  useEffect(() => {
    callback();
    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);
};

export const renderRoutesRecursively = (pages, className = 'nav-items') => {
  if (!pages) return null;
  return (
    <ul className={className}>
      {pages.map(({ path, title, childPages }, i) => (
        <li key={path}>
          <Link
            to={path}
            activeClassName={`${className}-active`}
            partiallyActive={i !== 0}
          >
            {title}
          </Link>
          {renderRoutesRecursively(childPages, `${className}-nested`)}
        </li>
      ))}
    </ul>
  );
};

export function slugify(str) {
  return slug(str, {
    lower: true, // lowercase everything
    charmap: {
      ä: 'ae',
      ü: 'ue',
      ö: 'oe',
      ß: 'ss',
      Ä: 'Ae',
      Ü: 'Ue',
      Ö: 'Oe',
    },
  });
}

export const mergeClassNames = classnames;

export const translate = (resources = [], key, returnKey = false, toString) => {
  if (!key) return null;
  const result = resources.find(
    (resource) => resource.key === key?.toLowerCase?.()
  );
  if (result) {
    return toString && result.value?.json
      ? documentToPlainTextString(result.value?.json, '\n')
      : result.value;
  }
  // console.info(`No translation found for ${key}`);
  return returnKey ? key : null;
};

export const initTranslate = (resources = [], returnKey) => (key) => {
  if (!Array.isArray(key)) return translate(resources, key, returnKey, true);
  return key.reduce(
    (acc, k) => ({
      ...acc,
      [k]: translate(resources, k, returnKey, true),
    }),
    {}
  );
};

export const useTranslate = (resources) => {
  const translate = useCallback(initTranslate(resources || [], true), [
    resources,
  ]);
  return translate;
};

export const initLocalized = ({ code, fallbackCode } = {}) => (property) =>
  property[code] || property[fallbackCode] || property;

export const useFetch = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (url, options) => {
    setIsLoading(true);
    try {
      const res = await fetch(url, options);
      try {
        setResponse(await res.json());
      } catch (error) {
        setResponse(await res.text());
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ response, error, isLoading }, fetchData];
};

export const isWindowSafe = () => typeof window !== 'undefined';

export const isPhone = () => isWindowSafe() && window.innerWidth < 480;

export const translateRoute = async (path, locale, locales) => {
  const { code: nextLocale } = locales.find((loc) => loc.code !== locale.code);
  const translatedPath = locale.localizedPaths[nextLocale];
  const sanitizedPath = translatedPath.replace(
    '{{slug}}',
    isWindowSafe() ? window.location.pathname?.split('/').pop() : ''
  );
  await navigate(`${sanitizedPath}${isWindowSafe() ? window.location.search : ''}`, { replace: true });
};

export function useEstates({ id, ...filter } = {}, locale = 'de', isProduction) {
  const [estates, setEstates] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const mapped = {
    marketingType: 'type',
    previewArea: 'livingarea[gte]',
    previewRooms: filter.previewRooms == 1 ? 'rooms[lte]' : 'rooms[gte]',
    search: 'search[like]',
  }

  const searchParams = new URLSearchParams(
    Object.entries({ ...filter, size: 30, locale })
      .filter(([, value]) => value !== ALL && value !== '' && value !== undefined)
      .map(([key, value]) => {
        if (key === 'estateType') {
          let types = getEstateTypesByBase(value);
          if (value === 'estatetype_06') {
            types = [
              ...types,
              ...getEstateTypesByBase('estatetype_05'),
            ]
          }
          return [
            'estatetype[in]',
            types.join(',')
          ]
        }
        return [mapped[key] || key, value];
      }))
    .toString();

  const basePath = isProduction ? '/api/estates' : '/api/dev-estates';
  const debouncedFetch = useCallback(
    debounce((id, searchParams) => {
      fetch(id ? `${basePath}/${id}?${searchParams}` : `${basePath}?${searchParams}`)
        .then((response) => response.json())
        .then((data) => setEstates(data))
        .finally(() => setIsLoading(false));
    }, 500),
    [basePath]
  )

  useEffect(() => {
    setIsLoading(true);
    debouncedFetch(id, searchParams)
  }, [id, searchParams, debouncedFetch]);

  return { isLoading, res: estates };

  function getEstateTypesByBase(estateKey) {
    return Object.keys(estatetypes)
      .filter((key) => key.startsWith(estateKey))
      .map((key) => key.replace(/^estatetype_/, ''));
  }
}

export function parseMarketingType(_metadata) {
  return _metadata.schema.includes('rent') ? 'rent' : _metadata.schema.includes('purchase') ? 'purchase' : 'purchase / rent';
}

export function parseAddress(addresses) {
  return [
    addresses.street,
    [addresses.zipcode, addresses.city].filter(Boolean).map(x => x.trim()).join(' '),
    addresses.country
  ].filter(Boolean).map(x => x.trim()).join(', ');
}


export function formatNumber(number, type, locale = 'de') {
  if (number === undefined || number === null) return;
  return type === 'currency' ?
    number.toLocaleString(locale, { style: 'currency', currency: 'EUR' }) :
    type === 'area' ?
      `${number.toLocaleString(locale, { style: 'decimal', })} m²` :
      number.toLocaleString(locale, { style: 'decimal', });
}

export function formatCurrency(number, locale = 'de') {
  return number === 1 ? undefined : isNaN(number) ? number : formatNumber(number, 'currency', locale);
}

export function formatArea(number, locale = 'de') {
  return formatNumber(number, 'area', locale);
}

export function getNetlifyImageUrl(url, queryParams = {}) {
  if (!url) return url;
  const isLocal = isWindowSafe() && window.location.hostname === 'localhost';
  const searchParams = new URLSearchParams({ fm: 'webp', ...queryParams });
  searchParams.set('url', url);
  const netlifyUrl = `/.netlify/images?${searchParams.toString()}`;
  if (isLocal) {
    console.log(netlifyUrl);
    return url;
  }
  return netlifyUrl;
}
