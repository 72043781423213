export const filterEstatetypes = [
  'estatetype_01',
  'estatetype_03',
  'estatetype_06',
  'estatetype_11g',
  'estatetype_11s'
];

export default {
  "estatetype": {
    "en": "Real estate type",
    "de": "Objektart"
  },
  "estatetype_02zfert": {
    "en": "Detached house",
    "de": "Fertighaus"
  },
  "estatetype_02rend": {
    "en": "Terraced house",
    "de": "Reihenendhaus"
  },
  "estatetype_02reh": {
    "en": "Townhouse",
    "de": "Reihenhaus"
  },
  "estatetype_02zrust": {
    "en": "Rustico",
    "de": "Rustico"
  },
  "estatetype_02burg": {
    "en": "Castle/Castle",
    "de": "Burg/Schloss"
  },
  "estatetype_02zb": {
    "en": "Special property",
    "de": "Besondere Immobilie"
  },
  "estatetype_02zherr": {
    "en": "Mansion",
    "de": "Herrenhaus"
  },
  "estatetype_02rehm": {
    "en": "Terraced middle house",
    "de": "Reihenmittelhaus"
  },
  "estatetype_02dzh": {
    "en": "Two-family house",
    "de": "Zweifamilienhaus"
  },
  "estatetype_02": {
    "en": "Houses",
    "de": "Häuser"
  },
  "estatetype_02zberg": {
    "en": "Mountain hut",
    "de": "Berghütte"
  },
  "estatetype_02son": {
    "en": "Other houses",
    "de": "Sonstige Häuser"
  },
  "estatetype_02zland": {
    "en": "Villa",
    "de": "Landhaus"
  },
  "estatetype_02zstra": {
    "en": "Beach house",
    "de": "Strandhaus"
  },
  "estatetype_02lbh": {
    "en": "Farmhouse",
    "de": "Bauernhaus"
  },
  "estatetype_02zapah": {
    "en": "Apartmenthouse",
    "de": "Apartmenthaus"
  },
  "estatetype_02bng": {
    "en": "Bungalow",
    "de": "Bungalow"
  },
  "estatetype_02zstad": {
    "en": "Townhouse",
    "de": "Stadthaus"
  },
  "estatetype_02zfinc": {
    "en": "Finca",
    "de": "Finca"
  },
  "estatetype_02dhh": {
    "en": "Semi-detached house",
    "de": "Doppelhaushälfte"
  },
  "estatetype_02zchal": {
    "en": "Chalet",
    "de": "Chalet"
  },
  "estatetype_02efh": {
    "en": "Detached house",
    "de": "Einfamilienhaus"
  },
  "estatetype_02rehe": {
    "en": "Reiheneckhaus",
    "de": "Reiheneckhaus"
  },
  "estatetype_02zrest": {
    "en": "Resthof",
    "de": "Resthof"
  },
  "estatetype_02efe": {
    "en": "Detached house with detached apartment",
    "de": "Einfamilienhaus mit Einliegerwohnung"
  },
  "estatetype_02mfh": {
    "en": "Housing",
    "de": "Mehrfamilienhaus"
  },
  "estatetype_02vil": {
    "en": "Villa",
    "de": "Villa"
  },
  "estatetype_02zlaub": {
    "en": "Laube/Datsche/Garden House",
    "de": "Laube/Datsche/Gartenhaus"
  },
  "estatetype_01terr": {
    "en": "Terrace apartment",
    "de": "Terrassenwohnung"
  },
  "estatetype_01zo": {
    "en": "Other type of dwelling",
    "de": "Sonstiger Wohnungstyp"
  },
  "estatetype_01zferie": {
    "en": "Apartment",
    "de": "Ferienwohnung"
  },
  "estatetype_01sout": {
    "en": "Basement",
    "de": "Souterrain"
  },
  "estatetype_01pent": {
    "en": "Penthouse",
    "de": "Penthouse"
  },
  "estatetype_01hp": {
    "en": "Hochparterre",
    "de": "Hochparterre"
  },
  "estatetype_01dach": {
    "en": "Attic",
    "de": "Dachgeschosswohnung"
  },
  "estatetype_01loft": {
    "en": "Loft apartment",
    "de": "Loftwohnung"
  },
  "estatetype_01etag": {
    "en": "Floor apartment",
    "de": "Etagenwohnung"
  },
  "estatetype_01zgaler": {
    "en": "Gallery",
    "de": "Galerie"
  },
  "estatetype_01zattik": {
    "en": "Attica apartment",
    "de": "Attikawohnung"
  },
  "estatetype_01gerd": {
    "en": "Ground floor apartment",
    "de": "Erdgeschosswohnung"
  },
  "estatetype_01zapart": {
    "en": "Apartment",
    "de": "Apartment"
  },
  "estatetype_01zrohd": {
    "en": "Raw roofing",
    "de": "Rohdachboden"
  },
  "estatetype_01": {
    "en": "Apartments",
    "de": "Wohnungen"
  },
  "estatetype_01mais": {
    "en": "Maisonette apartment",
    "de": "Maisonettewohnung"
  },
  "estatetype_03gs": {
    "en": "Land for social services",
    "de": "Grundstück für Soziales"
  },
  "estatetype_03be": {
    "en": "Building ground for single/two-family houses",
    "de": "Baugrund für Ein- / Zweifamilienhäuser"
  },
  "estatetype_03gl": {
    "en": "Land Land Land and forestry",
    "de": "Grundstück Land und Forstwirtschaft"
  },
  "estatetype_03gg": {
    "en": "Building land for trade",
    "de": "Baugrund für Gewerbe"
  },
  "estatetype_03bm": {
    "en": "Building ground for multi-family houses",
    "de": "Baugrund für Mehrfamilienhäuser"
  },
  "estatetype_03gf": {
    "en": "Building ground for leisure",
    "de": "Baugrund für Freizeit"
  },
  "estatetype_03bwg": {
    "en": "Building land for residential and commercial use",
    "de": "Baugrund für Wohnen und Gewerbe"
  },
  "estatetype_03": {
    "en": "Land",
    "de": "Grundstücke"
  },
  "estatetype_12h": {
    "en": "House",
    "de": "Haus"
  },
  "estatetype_12z": {
    "en": "Room",
    "de": "Zimmer"
  },
  "estatetype_12a": {
    "en": "Apartment",
    "de": "Apartment"
  },
  "estatetype_04zf": {
    "en": "Recreational property (Invest.)",
    "de": "Freizeitimmobilie (Invest.)"
  },
  "estatetype_04gwa": {
    "en": "Commercial property (Invest.)",
    "de": "Gewerbeanwesen (Invest.)"
  },
  "estatetype_04w": {
    "en": "Residential property (Invest.)",
    "de": "Wohnimmobilien (Invest.)"
  },
  "estatetype_04gwg": {
    "en": "Commercial Building, Business, Office (Invest.)",
    "de": "Geschäftshaus, Handel, Büro (Invest.)"
  },
  "estatetype_04gwi": {
    "en": "Industrial property (Invest.)",
    "de": "Industrieanwesen (Invest.)"
  },
  "estatetype_04sik": {
    "en": "Clinic (Invest.)",
    "de": "Klinik (Invest.)"
  },
  "estatetype_04z": {
    "en": "Other (Invest.)",
    "de": "Sonstiges (Invest.)"
  },
  "estatetype_04sip": {
    "en": "Nursing home (Invest.)",
    "de": "Pflegeheim (Invest.)"
  },
  "estatetype_04gwe": {
    "en": "Commercial unit (Invest.)",
    "de": "Gewerbeeinheit (Invest.)"
  },
  "estatetype_04w03": {
    "en": "Multi-family house (Invest.)",
    "de": "Mehrfamilienhaus (Invest.)"
  },
  "estatetype_04hil": {
    "en": "Store/Sales Area (Invest.)",
    "de": "Laden/Verkaufsfläche (Invest.)"
  },
  "estatetype_04w01": {
    "en": "Condominium (Invest.)",
    "de": "Eigentumswohnung (Invest.)"
  },
  "estatetype_04gah": {
    "en": "Hotel (Invest.)",
    "de": "Hotel (Invest.)"
  },
  "estatetype_04si": {
    "en": "Social real estate (Invest.)",
    "de": "Sozialimmobilien (Invest.)"
  },
  "estatetype_04his": {
    "en": "Supermarket (Invest.)",
    "de": "Supermarkt (Invest.)"
  },
  "estatetype_04w05": {
    "en": "Residential/commercial building (Invest.)",
    "de": "Wohn-/Geschäftshaus (Invest.)"
  },
  "estatetype_04hie": {
    "en": "Shopping centre (Invest.)",
    "de": "Einkaufszentrum (Invest.)"
  },
  "estatetype_04ga": {
    "en": "Restaurant / Inn (Invest.)",
    "de": "Gaststätte / Gasthaus (Invest.)"
  },
  "estatetype_04gws": {
    "en": "Service Center (Invest.)",
    "de": "Servicecenter (Invest.)"
  },
  "estatetype_04gwh": {
    "en": "Hall/Lager (Invest.)",
    "de": "Halle/Lager (Invest.)"
  },
  "estatetype_04gw": {
    "en": "Industrial and commercial real estate (Invest.)",
    "de": "Industrie- und Gewerbeimmobilien (Invest.)"
  },
  "estatetype_04hik": {
    "en": "Department store (Invest.)",
    "de": "Kaufhaus (Invest.)"
  },
  "estatetype_04w04": {
    "en": "Residential complex (Invest.)",
    "de": "Wohnanlage (Invest.)"
  },
  "estatetype_04zp": {
    "en": "Parking garage (Invest.)",
    "de": "Parkhaus (Invest.)"
  },
  "estatetype_04hif": {
    "en": "Specialist Market Centre (Invest.)",
    "de": "Fachmarktzentrum (Invest.)"
  },
  "estatetype_04hi": {
    "en": "Commercial real estate (Invest.)",
    "de": "Handelsimmobilien (Invest.)"
  },
  "estatetype_04": {
    "en": "Investment/investment objects",
    "de": "Anlage-/Investmentobjekte"
  },
  "estatetype_04gwb": {
    "en": "Office building (Invest.)",
    "de": "Bürogebäude (Invest.)"
  },
  "estatetype_04sib": {
    "en": "Supervised living (Invest.)",
    "de": "Betreutes Wohnen (Invest.)"
  },
  "estatetype_04w02": {
    "en": "Detached house (Invest.)",
    "de": "Einfamilienhaus (Invest.)"
  },
  "estatetype_05l": {
    "en": "Shop",
    "de": "Laden"
  },
  "estatetype_05f": {
    "en": "Sales hall",
    "de": "Verkaufshalle"
  },
  "estatetype_05a": {
    "en": "Exhibition",
    "de": "Ausstellungsfläche"
  },
  "estatetype_05e2": {
    "en": "Department store",
    "de": "Kaufhaus"
  },
  "estatetype_05lv": {
    "en": "Sales",
    "de": "Verkaufsfläche"
  },
  "estatetype_05e": {
    "en": "SB Market",
    "de": "SB-Markt"
  },
  "estatetype_05f1": {
    "en": "Factory Outlet",
    "de": "Factory Outlet"
  },
  "estatetype_05": {
    "en": "Retail space",
    "de": "Einzelhandelsflächen"
  },
  "estatetype_05k": {
    "en": "Kiosk",
    "de": "Kiosk"
  },
  "estatetype_05e1": {
    "en": "Shopping centre",
    "de": "Einkaufszentrum"
  },
  "estatetype_06a": {
    "en": "Studio",
    "de": "Atelier"
  },
  "estatetype_06a1": {
    "en": "Loft",
    "de": "Loft"
  },
  "estatetype_06ph": {
    "en": "Practice house",
    "de": "Praxishaus"
  },
  "estatetype_06pe": {
    "en": "Practice area",
    "de": "Praxisfläche"
  },
  "estatetype_06bl": {
    "en": "Office and warehouse building",
    "de": "Büro und Lagergebäude"
  },
  "estatetype_06b": {
    "en": "Office space",
    "de": "Bürofläche"
  },
  "estatetype_06be": {
    "en": "Office floor",
    "de": "Büroetage"
  },
  "estatetype_06bh": {
    "en": "Office",
    "de": "Bürohaus"
  },
  "estatetype_06g": {
    "en": "Commercial centre",
    "de": "Gewerbezentrum"
  },
  "estatetype_06": {
    "en": "Commercial",
    "de": "Gewerbeflächen"
  },
  "estatetype_06buge": {
    "en": "Office and commercial buildings",
    "de": "Büro- und Geschäftsgebäude"
  },
  "estatetype_06woge": {
    "en": "Residential and commercial buildings",
    "de": "Wohn- und Geschäftsgebäude"
  },
  "estatetype_06bg": {
    "en": "Office centre",
    "de": "Bürozentrum"
  },
  "estatetype_06p": {
    "en": "Practice",
    "de": "Praxis"
  },
  "estatetype_10s": {
    "en": "Special object",
    "de": "Spezialobjekt"
  },
  "estatetype_10ge": {
    "en": "Commercial unit",
    "de": "Gewerbeeinheit"
  },
  "estatetype_10gp": {
    "en": "Business park",
    "de": "Gewerbepark"
  },
  "estatetype_10": {
    "en": "Other objects",
    "de": "Sonstige Objekte"
  },
  "estatetype_10g": {
    "en": "Commercial space",
    "de": "Gewerbefläche"
  },
  "estatetype_10w": {
    "en": "Workshop (production)",
    "de": "Werkstatt (Produktion)"
  },
  "estatetype_10f": {
    "en": "Leisure centre",
    "de": "Freizeitanlage"
  },
  "estatetype_11": {
    "en": "Garage pitch",
    "de": "Garage Stellplatz"
  },
  "estatetype_11g": {
    "en": "Garage",
    "de": "Garage"
  },
  "estatetype_11d": {
    "en": "Duplex",
    "de": "Duplex"
  },
  "estatetype_11s": {
    "en": "Pitch",
    "de": "Stellplatz"
  },
  "estatetype_11p": {
    "en": "Parking space",
    "de": "Parkhausstellplatz"
  },
  "estatetype_11t": {
    "en": "Underground parking space",
    "de": "Tiefgaragenstellplatz"
  },
  "estatetype_11z": {
    "en": "Other",
    "de": "Sonstiges"
  },
  "estatetype_11c": {
    "en": "Carport",
    "de": "Carport"
  },
  "estatetype_07hi": {
    "en": "Production",
    "de": "Produktionsfläche"
  },
  "estatetype_07": {
    "en": "Production and storage areas",
    "de": "Produktions- und Lagerflächen"
  },
  "estatetype_07l": {
    "en": "Storage space",
    "de": "Lagerfläche"
  },
  "estatetype_07h": {
    "en": "Hall",
    "de": "Halle"
  },
  "estatetype_07lh": {
    "en": "Warehouse",
    "de": "Lagerhalle"
  },
  "estatetype_07w": {
    "en": "Workshop space",
    "de": "Werkstattsfläche"
  },
  "estatetype_07a": {
    "en": "Exhibition space (production)",
    "de": "Ausstellungsfläche (Produktion)"
  },
  "estatetype_07lho": {
    "en": "High-bay warehouses",
    "de": "Hochregallager"
  },
  "estatetype_07lkür": {
    "en": "Refrigerated shelf storage",
    "de": "Kühlregallager"
  },
  "estatetype_07hif": {
    "en": "Industrial hall with open space",
    "de": "Industriehalle mit Freifläche"
  },
  "estatetype_07sf": {
    "en": "Service area",
    "de": "Servicefläche"
  },
  "estatetype_07lfr": {
    "en": "Bearings with open space",
    "de": "Lager mit Freifläche"
  },
  "estatetype_07lsp": {
    "en": "Freight forwarding warehouse",
    "de": "Speditionslager"
  },
  "estatetype_07lkü": {
    "en": "Cold store",
    "de": "Kühlhaus"
  },
  "estatetype_08gae": {
    "en": "Restaurant",
    "de": "Gaststätte"
  },
  "estatetype_08pent": {
    "en": "Boarding house",
    "de": "Boardinghaus"
  },
  "estatetype_08rest": {
    "en": "Restaurant",
    "de": "Restaurant"
  },
  "estatetype_08hota": {
    "en": "Hotel properties",
    "de": "Hotelanwesen"
  },
  "estatetype_08b": {
    "en": "Bar",
    "de": "Bar"
  },
  "estatetype_08c": {
    "en": "Cafe",
    "de": "Cafe"
  },
  "estatetype_08": {
    "en": "Hotel and catering areas",
    "de": "Hotellerie- und Gastronomieflächen"
  },
  "estatetype_08hot": {
    "en": "Hotel",
    "de": "Hotel"
  },
  "estatetype_08pens": {
    "en": "Pension",
    "de": "Pension"
  },
  "estatetype_08f": {
    "en": "Holiday property",
    "de": "Ferienimmobilie"
  },
  "estatetype_08hotg": {
    "en": "Hotel garni",
    "de": "Hotel garni"
  },
  "estatetype_08d": {
    "en": "Discotheque",
    "de": "Diskothek"
  },
  "estatetype_08gahs": {
    "en": "Hostel",
    "de": "Gästehaus"
  },
  "estatetype_09vieh": {
    "en": "Livestock production",
    "de": "Viehwirtschaft"
  },
  "estatetype_09sonst": {
    "en": "Various farming properties",
    "de": "Sonstige Landwirtschaftsimmobilien"
  },
  "estatetype_09a1": {
    "en": "Estate",
    "de": "Anwesen"
  },
  "estatetype_09teich": {
    "en": "Pond / Fisheries management",
    "de": "Teich- u. Fischwirtschaft"
  },
  "estatetype_09w": {
    "en": "Vineyard",
    "de": "Weingut"
  },
  "estatetype_09": {
    "en": "Agriculture forestry",
    "de": "Land- / Forstwirtschaft"
  },
  "estatetype_09r": {
    "en": "Horse riding center",
    "de": "Reiterhof"
  },
  "estatetype_09acke": {
    "en": "Agricultural Property",
    "de": "Ackerbau"
  },
  "estatetype_09gart": {
    "en": "Gardening market",
    "de": "Gartenbau"
  },
  "estatetype_09scheu": {
    "en": "Barn",
    "de": "Scheunen"
  },
  "estatetype_09jagd": {
    "en": "Hunting forestry",
    "de": "Jagd- u. Forstwirtschaft"
  },
  "estatetype_09a2": {
    "en": "Farm",
    "de": "Bauernhof"
  },
  "estatetype_09jagdr": {
    "en": "Hunting district",
    "de": "Jagdrevier"
  },
  "estatetype_09auss": {
    "en": "Newly established farmstead",
    "de": "Aussiedlerhof"
  }
}
