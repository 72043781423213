import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton/dist';
import { ALL, isWindowSafe, mergeClassNames, useEstates, useTranslate } from '../utils';
import { Input } from './ContactForm';
import EstatePreview from './EstatePreview';
import styles from './Estates.module.css';
import NotificationCard from './NotificationCard';
import estateTypes, { filterEstatetypes } from './estatetypes';

const getInitialState = () => {
  const defaultState = {
    estateType: ALL,
    marketingType: ALL,
    previewArea: ALL,
    previewRooms: ALL,
    search: '',
  };

  const params = new URLSearchParams(
    isWindowSafe() ? window.location.search : ''
  );

  const state = Object.entries(defaultState).reduce((acc, [key, value]) => {
    return { ...acc, [key]: params.get(key) || value };
  }, {});

  return state;
};

const setQueryState = debounce((filters) => {
  const params = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => params.set(key, value));
  const queryParams = `?${params.toString()}`;
  isWindowSafe() && window.history.pushState('', '', queryParams);
}, 50);

const Estates = ({ resources, config }) => {
  const locale = config?.localization?.locale?.code || 'de';
  const isProduction = config?.env === 'production';

  const translate = useTranslate([
    ...resources,
    ...filterEstatetypes.map((key) => ({ key, value: estateTypes[key][locale] }))
  ]);

  const initialFilters = getInitialState();
  const [filters, setFilters] = useState(initialFilters);

  const { res, isLoading } = useEstates(filters, locale, isProduction);

  setQueryState(filters);

  const onSearch = ({ target }) => {
    setFilters((prev) => ({ ...prev, search: target.value }));
  };

  const onSelect = ({ target }) => {
    const { name, value } = target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters({ ...updatedFilters });
  };

  const renderOption = ({ label, value }) => (
    <option key={value} value={value}>
      {label}
    </option>
  );

  const textFrom = translate('estates.from');
  const textTo = translate('estates.to');

  return (
    <section className={styles.root}>
      <h1>{translate('estates.search-estates')}</h1>
      <form
        className={mergeClassNames(styles.form)}
      >
        <div className={styles.filterWrapper}>
          <select
            name="marketingType"
            value={filters.marketingType}
            onChange={onSelect}
          >
            {
              [
                { value: ALL, label: translate('filter.purchase / rent') },
                { value: 'purchase', label: translate('filter.purchase') },
                { value: 'rent', label: translate('filter.rent') },
              ].map(renderOption)
            }
          </select>
          <Input
            className={styles.search}
            type="text"
            value={filters.search}
            placeholder={translate('estates.search-filter')}
            onChange={onSearch}
            isLoading={isLoading}
          />
          <select
            name="estateType"
            onChange={onSelect}
            value={filters.estateType}
          >
            {
              [
                { value: ALL, label: translate('estates.all-tradetype') },
                ...filterEstatetypes.map((key) => ({ value: key, label: translate(key) }))
              ].map(renderOption)
            }
          </select>
          <select
            name="previewRooms"
            onChange={onSelect}
            disabled={filters.previewArea !== ALL}
            value={filters.previewRooms}
          >
            {
              [
                { value: ALL, label: translate('estates.all-rooms') },
                { value: 1, label: `${textTo} ${1}` },
                ...new Array(4).fill(1).map((_, i) => {
                  const value = (i + 2);
                  return { value: value, label: `${textFrom} ${value}` }
                })
              ].map(renderOption)
            }
          </select>
          <select
            name="previewArea"
            onChange={onSelect}
            disabled={filters.previewRooms !== ALL}
            value={filters.previewArea}
          >
            {
              [
                { value: ALL, label: translate('estates.all-area') },
                ...new Array(11).fill(1).map((_, i) => {
                  const value = (i + 2) * 10;
                  return { value: value, label: `${textFrom} ${value} m²` }
                }),
              ].map(renderOption)
            }
          </select>
        </div>
      </form>
      <div className={styles.grid}>
        {res.entries?.map((props) => (
          <EstatePreview
            key={props.id}
            basePath={config.path}
            resources={resources}
            config={config}
            {...props}
          />
        ))}
        {
          isLoading ?
            new Array(3).fill(1).map((_, i) => (
              <div key={i} className={styles.loader}>
                <Skeleton height="10rem" />
                <Skeleton count={Math.random() * (4 - 2 + 1) + 2} height="1.25rem" />
              </div>
            )) :
            <NotificationCard config={config} resources={resources} translate={'estates.empty-state'} />
        }
      </div>
    </section>
  );
};


Estates.propTypes = {
  estates: PropTypes.array,
};
Estates.defaultProps = {
  estates: [],
};

export default Estates;
